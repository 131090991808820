import type { HookContext } from '@feathersjs/feathers'

import store from '../../../redux/store'
import { selectBotDataByGuildId, selectBotsLoading, selectConnected, selectCurrentUser, selectGuildsData, selectGuildsLoading } from '../../../redux/selectors'
import { logoutAsync } from '../../../redux/features/users/usersSlice'
import { setConnected } from '../../../redux/features/connection'
import { dataAdded as botAdded, dataRemoved as botRemoved, findAsync as findBotsAsync } from '../../../redux/features/bots'
import { dataAdded as guildAdded, findAsync as findGuildsAsync, getAsync as getGuildAsync } from '../../../redux/features/guilds'
import { dataAdded as permissionAdded, findAsync as findPermissionsAsync } from '../../../redux/features/permissions'
import { dataAdded as pollAdded, dataRemoved as pollRemoved } from '../../../redux/features/polls'
import { dataAdded as pollVoteAdded, dataRemoved as pollVoteRemoved } from '../../../redux/features/pollVotes'
import { dataAdded as reactionRoleAdded, dataRemoved as reactionRoleRemoved } from '../../../redux/features/reactionRoles'
import { dataAdded as subscriptionAdded, findAsync as findSubscriptionsAsync } from '../../../redux/features/subscriptions'
import { createNotification } from '../../../redux/features/notifications/notificationsSlice'

import client, { socket } from '../feathersClient'

import type { Bot } from '../../../models/Bot'
import type { Guild } from '../../../models/Guild'
import type { Permission } from '../../../models/Permission'
import type { Poll } from '../../../models/Poll'
import type { PollVote } from '../../../models/PollVote'
import type { ReactionRole } from '../../../models/ReactionRole'
import type { Subscription } from '../../../models/Subscription'

import { isSubscriptionActive } from '../../subscription'
import { capitalizeFirstLetter, delay, mergeData, removeData } from '../../util'
import { url } from '../config'

type Role = NonNullable<Guild['roles']>[number] & { guildId?: string }

export class SyncManager {
  static discordMainBotOauthWindowByGuildId: Record<string, Window> = {}
  static discordMainBotOauthWindowCallbacksByGuildId: Record<string, () => void> = {}
  static discordPersonalizedBotOauthWindow?: Window
  static discordPersonalizedBotOauthWindowCallback?: () => void
  private static running: boolean = false
  private static reconnecting: boolean = false
  private static reconnectDelay: number = 50

  static async init(): Promise<void> {
    if (SyncManager.running) {
      return
    }

    SyncManager.running = true

    store.dispatch(setConnected(true))

    SyncManager.initCsrfHooks()
    SyncManager.fetchInitialData()
    SyncManager.initSocketEvents()
    SyncManager.initSyncOnEvent()
  }

  static setDiscordMainBotOauthWindowByGuildId(guildId: string, window: Window, callback?: () => void): void {
    SyncManager.discordMainBotOauthWindowByGuildId[guildId] = window
    if (callback) {
      SyncManager.discordMainBotOauthWindowCallbacksByGuildId[guildId] = callback
    }
  }

  static setDiscordPersonalizedBotOauthWindow(window: Window, callback?: () => void): void {
    SyncManager.discordPersonalizedBotOauthWindow = window
    if (callback) {
      SyncManager.discordPersonalizedBotOauthWindowCallback = callback
      const checkIfWindowClosed = () => {
        if (window.closed) {
          clearInterval(intervalId)
          callback()
        }
      }

      const intervalId = setInterval(checkIfWindowClosed, 250)
    }
  }

  private static initCsrfHooks(): void {
    const sendCsrfToken = (context: HookContext) => {
      const user = selectCurrentUser(store.getState())
      if (user && context.path !== 'authentication') {
        if (context.params.query) {
          context.params.query.csrfToken = user.csrfToken
        } else {
          context.params.query = { csrfToken: user.csrfToken }
        }
      }
    }

    client.hooks({
      before: {
        create: [sendCsrfToken],
        patch: [sendCsrfToken],
        update: [sendCsrfToken],
        remove: [sendCsrfToken]
      }
    })
  }

  private static async fetchInitialData(): Promise<void> {
    store.dispatch(findBotsAsync())
    store.dispatch(findGuildsAsync())
    let shouldFetchGuilds = true
    store.subscribe(() => {
      const botsLoading = selectBotsLoading(store.getState())
      const guildsLoading = selectGuildsLoading(store.getState())
      if (shouldFetchGuilds && !botsLoading && !guildsLoading) {
        shouldFetchGuilds = false
        const guildsArray = selectGuildsData(store.getState())
        guildsArray.forEach((guild: Guild) => {
          if (guild.hasBot) {
            store.dispatch(getGuildAsync({ id: guild.id }))
            store.dispatch(findPermissionsAsync({ query: { guildId: guild.id } }))
            store.dispatch(findSubscriptionsAsync({ query: { guildId: guild.id } }))
          }
        })
      }
    })
  }

  private static initSocketEvents(): void {
    const handleReconnect = async (): Promise<void> => {
      const state = store.getState()
      const connected = selectConnected(state)
      if (SyncManager.reconnecting || connected) {
        return
      }

      SyncManager.reconnecting = true

      try {
        await delay(SyncManager.reconnectDelay)

        const accessToken = window.localStorage.getItem('feathers-jwt')
        const refreshToken = window.localStorage.getItem('feathers-rt')
        const fetchResponse = await fetch(`${url}refresh-tokens`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ accessToken, refreshToken })
        })

        if (!fetchResponse.ok) {
          if (fetchResponse.status === 400) {
            window.localStorage.removeItem('feathers-jwt')
            window.localStorage.removeItem('feathers-rt')
            store.dispatch(createNotification({ type: 'error', message: 'Authentication failed. Please login again.' }))
            store.dispatch(logoutAsync())
            SyncManager.reconnectDelay += 500
            SyncManager.reconnecting = false
            return
          } else {
            SyncManager.reconnectDelay += 500
            SyncManager.reconnecting = false
            return handleReconnect()
          }
        }

        const result = await fetchResponse.json()
        window.localStorage.setItem('feathers-rt', result.refreshToken)
        window.localStorage.setItem('feathers-jwt', result.accessToken)

        socket.connect()
      } catch (e: unknown) {
        SyncManager.reconnectDelay += 500
        SyncManager.reconnecting = false
        return handleReconnect()
      }
    }

    const handleDisconnect = () => {
      const state = store.getState()
      const connected = selectConnected(state)
      if (connected) {
        store.dispatch(setConnected(false))
      }

      handleReconnect()
    }

    socket.on('connect', async () => {
      const state = store.getState()
      const connected = selectConnected(state)
      if (!connected) {
        store.dispatch(setConnected(true))
      }
      await client.reAuthenticate(true)
      SyncManager.reconnectDelay = 50
      SyncManager.reconnecting = false
    })

    socket.on('disconnect', handleDisconnect)
    socket.on('connect_error', handleDisconnect)
    socket.on('connect_timeout', handleDisconnect)
    socket.on('reconnect_attempt', handleDisconnect)
    socket.on('reconnect_error', handleDisconnect)
    socket.on('reconnect_failed', handleDisconnect)
    socket.on('error', handleDisconnect)
    socket.io.on('close', handleDisconnect)
  }

  private static initSyncOnEvent(): void {
    client.service('bots').on('created', (bot: Bot) => {
      const state = store.getState()
      store.dispatch(botAdded(bot))
      const guild = state.guilds.data.find((guild: Guild) => guild.id === bot.guildId)!
      store.dispatch(createNotification({ type: 'success', message: `Bot successfully added to "${guild.name}"` }))
      store.dispatch(getGuildAsync({ id: guild.id }))
      SyncManager.discordMainBotOauthWindowByGuildId[guild.id]?.close()
      SyncManager.discordMainBotOauthWindowCallbacksByGuildId[guild.id]?.()
    })

    client.service('bots').on('patched', (bot: Bot) => {
      const state = store.getState()
      const existingBot = selectBotDataByGuildId(bot.guildId, state)
      store.dispatch(botAdded(bot))
      const guild = state.guilds.data.find((guild: Guild) => guild.id === bot.guildId)
      if (guild && bot.active) {
        store.dispatch(getGuildAsync({ id: guild.id }))
        if (existingBot?.personalizedBotTokenClientId && !bot.personalizedBotTokenClientId) {
          store.dispatch(
            createNotification({
              type: 'success',
              message: `Personalized bot successfully added to "${guild.name}"`
            })
          )
          SyncManager.discordPersonalizedBotOauthWindow?.close()
          SyncManager.discordPersonalizedBotOauthWindowCallback?.()
        } else if (!existingBot?.active && bot.active) {
          SyncManager.discordMainBotOauthWindowByGuildId[guild.id]?.close()
          SyncManager.discordMainBotOauthWindowCallbacksByGuildId[guild.id]?.()
        }
      }
    })

    client.service('bots').on('removed', (bot: Bot) => {
      const state = store.getState()
      store.dispatch(botRemoved(bot))
      const guild = state.guilds.data.find((guild: Guild) => guild.id === bot.guildId)
      if (guild) {
        store.dispatch(createNotification({ type: 'alert', message: `Bot successfully removed from "${guild.name}"` }))
      }
      store.dispatch(findGuildsAsync())
    })

    client.service('guilds').on('roleAdded', (role: Role) => {
      const state = store.getState()
      const guild = state.guilds.data.find((guild: Guild) => guild.id === role.guildId)
      const roleExists = guild?.roles?.find((guildRole: Role) => guildRole.id === role.id)
      if (guild && guild.roles) {
        const { guildId, ...roleWithoutGuildId } = role
        const guildData = {
          ...guild,
          roles: mergeData(JSON.parse(JSON.stringify(guild.roles)), roleWithoutGuildId)
        }
        store.dispatch(guildAdded(guildData))
      }
      if (window.location.pathname === `/dashboard/${guild?.id}/reaction-roles/add`) {
        store.dispatch(createNotification({ type: 'alert', message: `Role ${role.name} has been ${roleExists ? 'updated' : 'added'}` }))
      }
    })

    client.service('guilds').on('roleRemoved', (role: Role) => {
      const state = store.getState()
      const guild = state.guilds.data.find((guild: Guild) => guild.id === role.guildId)!
      const roleToRemove = guild.roles?.find((guildRole: Role) => guildRole.id === role.id)
      if (guild && guild.roles) {
        const guildData = {
          ...guild,
          roles: removeData(JSON.parse(JSON.stringify(guild.roles)), role)
        }
        store.dispatch(guildAdded(guildData))
      }
      if (roleToRemove && window.location.pathname === `/dashboard/${guild?.id}/reaction-roles/add`) {
        store.dispatch(createNotification({ type: 'alert', message: `Role ${roleToRemove.name} has been removed` }))
      }
    })

    client.service('guilds').on('rolesUpdated', ({ guildId, roles }: { guildId: string; roles: Guild['roles'] }) => {
      const state = store.getState()
      const guild = state.guilds.data.find((guild: Guild) => guild.id === guildId)!
      if (guild) {
        const guildData = {
          ...guild,
          roles
        }
        store.dispatch(guildAdded(guildData))
      }
      if (window.location.pathname === `/dashboard/${guildId}/reaction-roles/add`) {
        store.dispatch(createNotification({ type: 'alert', message: 'Roles have been updated' }))
      }
    })

    client.service('guilds').on('userRolesUpdated', (guild: Guild & { discordId?: string }) => {
      delete guild.discordId
      store.dispatch(guildAdded(guild))
    })

    client.service('guilds').on('channelCreated', (channel: { guildId: string }) => {
      store.dispatch(getGuildAsync({ id: channel.guildId }))
    })

    client.service('guilds').on('channelRemoved', (channel: { id: string; guildId: string }) => {
      const state = store.getState()
      const guild = state.guilds.data.find((guild: Guild) => guild.id === channel.guildId)!
      const channels = removeData(JSON.parse(JSON.stringify(guild.channels)), {
        id: channel.id,
        name: '',
        type: ''
      })
      if (guild && guild.roles) {
        const guildData = {
          ...guild,
          channels
        }
        store.dispatch(guildAdded(guildData))
      }
    })

    client.service('permissions').on('created', (permission: Permission) => {
      store.dispatch(permissionAdded(permission))
    })

    client.service('polls').on('created', (poll: Poll) => {
      store.dispatch(pollAdded(poll))
    })

    client.service('polls').on('patched', (poll: Poll) => {
      store.dispatch(pollAdded(poll))
    })

    client.service('polls').on('removed', (poll: Poll) => {
      store.dispatch(pollRemoved(poll))
    })

    client.service('poll-votes').on('created', (pollVote: PollVote) => {
      store.dispatch(pollVoteAdded(pollVote))
    })

    client.service('poll-votes').on('removed', (pollVote: PollVote) => {
      store.dispatch(pollVoteRemoved(pollVote))
    })

    client.service('reaction-roles').on('created', (reactionRole: ReactionRole) => {
      store.dispatch(reactionRoleAdded(reactionRole))
    })

    client.service('reaction-roles').on('patched', (reactionRole: ReactionRole) => {
      store.dispatch(reactionRoleAdded(reactionRole))
    })

    client.service('reaction-roles').on('removed', (reactionRole: ReactionRole) => {
      store.dispatch(reactionRoleRemoved(reactionRole))
    })

    client.service('subscriptions').on('created', (subscription: Subscription) => {
      store.dispatch(subscriptionAdded(subscription))
    })

    client.service('subscriptions').on('patched', (subscription: Subscription) => {
      const state = store.getState()
      const originalSubscription = state.subscriptions.data.find((sub: Subscription) => sub.id === subscription.id)!
      const guild = state.guilds.data.find((guild: Guild) => guild.id === originalSubscription.guildId)!

      store.dispatch(subscriptionAdded(subscription))

      if (isSubscriptionActive(subscription) && !isSubscriptionActive(originalSubscription)) {
        store.dispatch(createNotification({ type: 'success', message: `Subscription successfully activated for server "${guild.name}"` }))
      } else if (originalSubscription.plan !== subscription.plan) {
        store.dispatch(
          createNotification({
            type: 'success',
            message: `Subscription successfully upgraded to ${capitalizeFirstLetter(subscription.plan)} for server "${guild.name}"`
          })
        )
      } else if (originalSubscription.planDuration !== subscription.planDuration) {
        store.dispatch(
          createNotification({
            type: 'success',
            message: `Subscription duration successfully upgraded to ${subscription.planDuration.split('-')[0]} months for server "${guild.name}"`
          })
        )
      } else if (isSubscriptionActive(originalSubscription) && !originalSubscription.providerErrorMessage && subscription.providerErrorMessage) {
        store.dispatch(createNotification({ type: 'error', message: `Subscription error for server "${guild.name}": ${subscription.providerErrorMessage}` }))
      }
    })
  }

  private constructor() {}
}
