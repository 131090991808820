import { connect } from 'react-redux'

import type { AppDispatch, RootState } from '../../../../redux/store'
import { selectBotsLoading, selectGuildData, selectGuildsLoading, selectPermissionsLoading } from '../../../../redux/selectors'

import { LeavingMessageView } from './LeavingMessageView'

interface OwnProps {
  guildId: string
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  guild: selectGuildData(ownProps.guildId, state),
  loading: selectBotsLoading(state) || selectGuildsLoading(state) || selectPermissionsLoading(state)
})

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: OwnProps) => ({})

export const LeavingMessage = connect(mapStateToProps, mapDispatchToProps)(LeavingMessageView)
