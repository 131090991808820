import type { Action, PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { Params } from '@feathersjs/feathers'

import type { Subscription, SubscriptionData, SubscriptionPatch } from '../../../models/Subscription'

import { Api } from '../../../lib/api'
import { mergeData, removeData } from '../../../lib/util'

import { createNotification } from '../notifications/notificationsSlice'

interface DataState {
  modifying: boolean
  loading: boolean
  data: Subscription[]
  errorMessage?: string
}

type ICreateParams = {
  data: SubscriptionData
  params?: Params
}

type IPatchParams = {
  id: string
  data: SubscriptionPatch
  params?: Params & { preview?: boolean }
}

const initialState: DataState = {
  modifying: false,
  loading: false,
  data: []
}

const handlePending = (state: DataState) => {
  delete state.errorMessage
  state.loading = true
}

const handleRejection = (state: DataState, action: Action<string> & { error: { message?: string } }) => {
  state.errorMessage = action.error.message
  state.loading = false
}

export const findAsync = createAsyncThunk('subscriptions/find', async (params: Params, { dispatch }): Promise<Subscription[]> => {
  try {
    const { data } = await Api.find('subscriptions', params)
    return data
  } catch (e: unknown) {
    dispatch(createNotification({ type: 'error', message: `Error getting subscriptions: ${(e as Error).message}` }))
    throw e
  }
})

export const createAsync = createAsyncThunk('subscriptions/create', async ({ data, params }: ICreateParams, { dispatch }): Promise<Subscription | void> => {
  try {
    return await Api.create('subscriptions', data, params)
  } catch (e: unknown) {
    dispatch(createNotification({ type: 'error', message: `Error creating subscription: ${(e as Error).message}` }))
    throw e
  }
})

export const patchAsync = createAsyncThunk('subscriptions/update', async ({ id, data, params }: IPatchParams, { dispatch }): Promise<Subscription> => {
  try {
    return await Api.patch('subscriptions', id, data, params)
  } catch (e: unknown) {
    dispatch(createNotification({ type: 'error', message: `Error updating subscription: ${(e as Error).message}` }))
    throw e
  }
})

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    dataAdded: (state, action: PayloadAction<Subscription | Subscription[]>) => {
      state.data = mergeData(state.data, action.payload)
    },
    dataRemoved: (state, action: PayloadAction<Subscription | Subscription[]>) => {
      state.data = removeData(state.data, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAsync.pending, handlePending)
      .addCase(findAsync.fulfilled, (state, action) => {
        state.data = mergeData(state.data, action.payload)
        state.loading = false
      })
      .addCase(findAsync.rejected, handleRejection)
      .addCase(createAsync.pending, (state: DataState) => {
        delete state.errorMessage
        state.modifying = true
      })
      .addCase(createAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = mergeData(state.data, action.payload)
        }
        state.modifying = false
      })
      .addCase(createAsync.rejected, (state: DataState, action: Action<string> & { error: { message?: string } }) => {
        state.errorMessage = action.error.message
        state.modifying = false
      })
      .addCase(patchAsync.pending, (state: DataState, action) => {
        delete state.errorMessage
        state.modifying = true
      })
      .addCase(patchAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = mergeData(state.data, action.payload)
        }
        state.modifying = false
      })
      .addCase(patchAsync.rejected, (state: DataState, action: Action<string> & { error: { message?: string } }) => {
        state.errorMessage = action.error.message
        state.modifying = false
      })
  }
})

export const { dataAdded, dataRemoved } = subscriptionsSlice.actions

export default subscriptionsSlice.reducer
