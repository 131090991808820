import { useEffect } from 'react'
import Container from 'react-bootstrap/Container'

import type { Notification } from '../../redux/features/notifications/notificationsSlice'

import { NotificationToast } from './components/NotificationToast'

import './styles.css'

interface Props {
  notifications: Notification[]
  removeNotification: (id: string) => void
}

export const NotificationsView = ({ notifications, removeNotification }: Props) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        const lastNotification = notifications[notifications.length - 1]
        if (lastNotification) {
          removeNotification(lastNotification.id)
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [notifications, removeNotification])

  return (
    <Container className="notification-container">
      {notifications.map((notification: Notification) => {
        const onClose =
          (id: string): (() => void) =>
          () => {
            removeNotification(id)
          }
        return <NotificationToast key={notification.id} notification={notification} onClose={onClose(notification.id)} />
      })}
    </Container>
  )
}
