import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'

import { formatDateShort } from '../../../../lib/util'

import type { Guild } from '../../../../models/Guild'
import type { Poll } from '../../../../models/Poll'

import { ChannelPickerView as ChannelPicker } from '../../../ChannelPicker'
import { PageHeaderView as PageHeader } from '../../../PageHeader'
import { Loading } from '../../../Loading'
import { NoBotInGuildView } from '../../../Bots/NoBotInGuild'

interface Props {
  canCreate: boolean
  guildId: string
  loading: boolean
  polls: Poll[]

  guild?: Guild

  load: () => void
}

export const PollsView = ({ canCreate, load, loading, guild, polls }: Props) => {
  const navigate = useNavigate()

  useEffect(load, [load])

  return loading ? (
    <Loading style={{ marginTop: '32vh' }} />
  ) : !guild?.hasBot ? (
    <NoBotInGuildView guild={guild} />
  ) : (
    <Container>
      <PageHeader
        backButtonPath={`/dashboard/${guild?.id}`}
        rightButton={
          canCreate ? (
            <ChannelPicker channels={guild?.channels} onChannelClick={(channel) => navigate(`/dashboard/${guild.id}/${channel.id}/polls/add`)} />
          ) : null
        }
      >
        Polls
      </PageHeader>
      {!polls.length ? (
        <h2 className="text-center" style={{ marginTop: '32vh' }}>
          There are no polls in this server yet.
        </h2>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th className="col-2">Title</th>
              <th className="col-4">Description</th>
              <th className="col-1 text-center">Anonymous</th>
              <th className="col-2 text-center">Created At</th>
              <th className="col-2 text-center">Closes At</th>
              <th className="col-1 text-center">Closed</th>
            </tr>
          </thead>
          <tbody>
            {polls.map((poll, index) => (
              <tr key={index} className="cursor-pointer" onClick={() => navigate(`/dashboard/${poll.guildId}/polls/${poll.id}`)}>
                <td className="col-2">{poll.title}</td>
                <td className="col-4">{poll.description}</td>
                <td className="col-1 text-center">{poll.isAnonymous ? 'Yes' : 'No'}</td>
                <td className="col-2 text-center">{formatDateShort(poll.createdAt)}</td>
                <td className="col-2 text-center">{poll.closesAt ? formatDateShort(poll.closesAt) : 'N/A'}</td>
                <td className="col-1 text-center">{!!poll.closedAt ? 'Yes' : 'No'}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  )
}
