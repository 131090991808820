import { connect } from 'react-redux'

import type { AppDispatch, RootState } from '../../redux/store'

import { selectNotifications } from '../../redux/selectors'
import { removeNotification } from '../../redux/features/notifications/notificationsSlice'
import { NotificationsView } from './NotificationsView'

const mapStateToProps = (state: RootState) => {
  return {
    notifications: selectNotifications(state)
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  removeNotification: (id: string): void => {
    dispatch(removeNotification({ id }))
  }
})

export const Notifications = connect(mapStateToProps, mapDispatchToProps)(NotificationsView)
