import { connect } from 'react-redux'

import type { AppDispatch, RootState } from '../../../../../redux/store'
import {
  selectBotsLoading,
  selectCurrentUser,
  selectCurrentThemeType,
  selectGuildData,
  selectGuildsLoading,
  selectPollsLoading
} from '../../../../../redux/selectors'
import { closeAll, open } from '../../../../../redux/features/modal/modalSlice'
import { createNotification } from '../../../../../redux/features/notifications/notificationsSlice'
import { createAsync } from '../../../../../redux/features/polls/pollsSlice'

import type { Poll, PollData } from '../../../../../models/Poll'

import { AddPollView } from './AddPollView'

interface OwnProps {
  guildId: string
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  return {
    currentUser: selectCurrentUser(state),
    currentThemeType: selectCurrentThemeType(state),
    guild: selectGuildData(ownProps.guildId, state),
    loading: selectBotsLoading(state) || selectGuildsLoading(state) || selectPollsLoading(state)
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  openModal: (modalName: string): void => {
    dispatch(open(modalName))
  },
  addPoll: async (data: PollData): Promise<Poll | undefined> => {
    dispatch(closeAll())
    try {
      const result = await dispatch(createAsync({ data }))
      return result.payload as Poll
    } catch (e: unknown) {
      return
    }
  },
  channelDeleted: () => {
    dispatch(createNotification({ type: 'error', message: 'This channel has been deleted from the Discord server' }))
  }
})

export const AddPoll = connect(mapStateToProps, mapDispatchToProps)(AddPollView)
