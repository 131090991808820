import { useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

import { hasDuplicates } from '../../../../../../../lib/util'
import { getValueForRuleOfField, getOptionalFields, resolver } from '../../../../../../../lib/validation'
import type { PollData } from '../../../../../../../models/Poll'
import type { PollOptionData } from '../../../../../../../models/PollOption'
import { DatePickerView as DatePicker } from '../../../../../../Forms/DatePicker/DatePickerView'

const emojiData = data as any // eslint-disable-line
const emojisByKey: Record<number | string, string> = {
  1: emojiData.emojis.one.skins[0].native,
  2: emojiData.emojis.two.skins[0].native,
  3: emojiData.emojis.three.skins[0].native,
  4: emojiData.emojis.four.skins[0].native,
  5: emojiData.emojis.five.skins[0].native,
  6: emojiData.emojis.six.skins[0].native,
  7: emojiData.emojis.seven.skins[0].native,
  8: emojiData.emojis.eight.skins[0].native,
  9: emojiData.emojis.nine.skins[0].native,
  tu: emojiData.emojis['+1'].skins[0].native,
  td: emojiData.emojis['-1'].skins[0].native
}

interface Props {
  creatorId: string
  channelId: string
  guildId: string
  currentThemeType: string
  onSubmit: (data: PollData) => void
  onCancel?: () => void
}

const timeRegexp = /^((0?[1-9])|(1[0-2])):[0-5][0-9]$/

const timeRegexps = [/^[0-1]$/, /^0?[1-9]$|^1[0-2]$/, /^((0?[1-9])|(1[0-2])):$/, /^((0?[1-9])|(1[0-2])):[0-5]$/, timeRegexp]

const formatPollOptionErrorMessage = (message?: string): string => {
  if (!message) {
    return ''
  }

  return message.replace(/^PollOptions\/\d+\/text/, 'Poll option text')
}

const formatClosesAtDate = (date: Date, hoursAndMinutes: string, amOrPm: string): Date => {
  const newDate = new Date(date.getTime())
  const [hours, minutes] = hoursAndMinutes.split(':').map(Number)
  newDate.setHours(amOrPm === 'PM' ? hours + 12 : hours)
  newDate.setMinutes(minutes)
  return newDate
}

type TimeUnit = 'minutes' | 'hours' | 'days'
const formatClosesInDate = (amount: number, unit: TimeUnit): Date => {
  const futureDate = new Date()

  switch (unit) {
    case 'minutes':
      futureDate.setMinutes(futureDate.getMinutes() + amount)
      break
    case 'hours':
      futureDate.setHours(futureDate.getHours() + amount)
      break
    case 'days':
      futureDate.setDate(futureDate.getDate() + amount)
      break
  }

  return futureDate
}

let localErrors: Record<string, { message: string; type?: string }> = {}

// eslint-disable-next-line
const preprocessData = (data: Record<string, any>) => {
  const optionalFields = getOptionalFields('PollData')
  optionalFields.forEach((optionalField: string) => {
    if (data[optionalField] === '') {
      delete data[optionalField]
    }
  })
  if (data.isAnonymous) {
    data.pollOptions.forEach((pollOption: PollOptionData, index: number) => {
      delete data.pollOptions[index].emoji
    })
  }
  return data
}

export const PollFormView = ({ currentThemeType, creatorId, channelId, guildId, onSubmit, onCancel }: Props) => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [closesInOrAt, setClosesInOrAt] = useState('')
  const [closesAtDate, setClosesAtDate] = useState(new Date())
  const [closesAtAmOrPm, setClosesAtAmOrPm] = useState('PM')
  const [closesAtHoursAndMinutes, setClosesAtHoursAndMinutes] = useState('')
  const [closesInMinutesDaysOrHoursAmount, setClosesInMinutesDaysOrHoursAmount] = useState(1)
  const [closesInMinutesDaysOrHours, setClosesInMinutesDaysOrHours] = useState<TimeUnit>('hours')
  const [showEmojiPicker, setShowEmojiPicker] = useState<Record<string, boolean>>({})

  const {
    register,
    setError,
    clearErrors,
    handleSubmit,
    setFocus,
    setValue,
    control,
    watch,
    formState: { errors }
  } = useForm<PollData>({
    resolver: async (data) => {
      setIsSubmitted(true)

      let closesAtValue = ''
      if (closesInOrAt === 'at' && closesAtHoursAndMinutes) {
        closesAtValue = formatClosesAtDate(closesAtDate, closesAtHoursAndMinutes, closesAtAmOrPm).toISOString()
      } else if (closesInOrAt === 'in' && closesInMinutesDaysOrHoursAmount) {
        closesAtValue = formatClosesInDate(closesInMinutesDaysOrHoursAmount, closesInMinutesDaysOrHours).toISOString()
      }

      const newData = { ...data, closesAt: closesAtValue }
      const result = await resolver('create', 'polls')(preprocessData(newData))

      localErrors = result.errors
      if (localErrors.closesAt?.type === 'format') {
        localErrors.closesAt.message = 'A closing time is required'
      } else if (closesInOrAt === 'at' && !timeRegexp.test(closesAtHoursAndMinutes)) {
        localErrors.closesAt = { message: 'A closing time is required', type: 'required' }
      } else if ((closesInOrAt === 'at' && timeRegexp.test(closesAtHoursAndMinutes)) || closesInOrAt === 'in') {
        if (new Date(closesAtValue).getTime() < Date.now()) {
          localErrors.closesAt = { message: 'Closing time must be in the future', type: 'required' }
        } else {
          delete localErrors.closesAt
        }
      }

      setError('pollOptions.0.text', { type: 'custom', message: 'custom message' })

      const allEmojisSet = newData.pollOptions.every((pollOption: PollOptionData) => pollOption.emoji)
      const duplicateEmojisUsed = hasDuplicates(newData.pollOptions, 'emoji')

      if (allEmojisSet && duplicateEmojisUsed) {
        const duplicateEmojiError = { type: 'custom', message: 'Cannot use the same emoji for more than one option' }
        setError('pollOptions', duplicateEmojiError)
        if (typeof result.errors === 'object' && result.errors !== null) {
          ;(result.errors as Record<string, any>).pollOptions = duplicateEmojiError // eslint-disable-line
        }
      }

      return result
    },
    defaultValues: {
      title: '',
      description: '',
      isAnonymous: false,
      channelId,
      guildId,
      creatorId,
      closesAt: '',
      pollOptions: [
        {
          text: '',
          emoji: emojisByKey[1],
          guildId
        },
        {
          text: '',
          emoji: emojisByKey[2],
          guildId
        }
      ]
    }
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'pollOptions'
  })

  const watchDescription = watch('description')
  const isAnonymous = watch('isAnonymous')
  const firstOptionField = watch('pollOptions.0.text')
  const secondOptionField = watch('pollOptions.1.text')

  useEffect(() => {
    setTimeout(() => {
      setFocus('title')
    }, 0)
  }, [setFocus])

  useEffect(() => {
    if (firstOptionField === 'Yes' || firstOptionField === 'yes' || firstOptionField === 'Ok' || firstOptionField === 'ok') {
      setValue('pollOptions.0.emoji', emojisByKey.tu)
    }
  }, [firstOptionField, setValue])

  useEffect(() => {
    if (secondOptionField === 'No' || secondOptionField === 'no') {
      setValue('pollOptions.1.emoji', emojisByKey.td)
    }
  }, [secondOptionField, setValue])

  const handleSetClosesAtHoursAndMinutes = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length && timeRegexps[event.target.value.length - 1]?.test(event.target.value)) {
      setClosesAtHoursAndMinutes(event.target.value.length === 2 && closesAtHoursAndMinutes.length !== 3 ? `${event.target.value}:` : event.target.value)
    } else if (!event.target.value.length && closesAtHoursAndMinutes.length === 1) {
      setClosesAtHoursAndMinutes(event.target.value)
    }
  }

  const descriptionMaxLength = getValueForRuleOfField('PollData', 'description', 'maxLength')

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2" htmlFor="rtn-form-poll-title" className="fw-bold">
            Title:
          </Form.Label>
          <Col sm="10">
            <Form.Control id="rtn-form-poll-title" isInvalid={!!errors.title?.message} {...register('title')} />
            <Form.Control.Feedback type="invalid">{errors.title?.message}</Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-1">
          <Form.Label column sm="2" htmlFor="rtn-form-poll-description" className="fw-bold">
            Question (Optional):
          </Form.Label>
          <Col sm="10">
            <Form.Control
              id="rtn-form-poll-description"
              as="textarea"
              rows={3}
              isInvalid={!!errors.description?.message || (watchDescription?.length || 0) > descriptionMaxLength}
              {...register('description')}
            />
            <Form.Control.Feedback type="invalid">{errors.description?.message}</Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Row className="d-flex mb-3">
          <Col className={(watchDescription?.length || 0) > descriptionMaxLength ? 'text-danger text-end' : 'text-end'}>
            {watchDescription?.length || 0} / {descriptionMaxLength}
          </Col>
        </Row>
        <Row className="mb-4 form-control-invalid">
          <Col sm={4} className="d-flex align-items-center fw-bold">
            Closes (Optional)
            <select
              className="form-select form-select-sm mx-2"
              style={{ width: 'auto', display: 'inline' }}
              value={closesInOrAt}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                delete localErrors.closesAt
                setClosesInOrAt(event.target.value)
              }}
              aria-label="Closes 'in' or 'at' dropdown"
            >
              <option value="">manually</option>
              <option value="at">at</option>
              <option value="in">in</option>
            </select>
            {closesInOrAt ? <span style={{ position: 'relative', right: '5px' }}>:</span> : null}
          </Col>
          {closesInOrAt === 'at' ? (
            <>
              <Col sm={4}>
                <DatePicker
                  className="form-control"
                  value={closesAtDate}
                  onChange={(date: Date | null) => {
                    if (date) {
                      setClosesAtDate(date)
                    }
                  }}
                  dateFormat="MMMM d, yyyy"
                  minDate={new Date()}
                  inline
                />
              </Col>
              <Col sm={2}>
                <Form.Control
                  isInvalid={isSubmitted && closesAtHoursAndMinutes !== '' && !timeRegexps[timeRegexps.length - 1].test(closesAtHoursAndMinutes)}
                  name="closesAtHoursAndMinutes"
                  placeholder="hh:mm"
                  value={closesAtHoursAndMinutes}
                  onChange={handleSetClosesAtHoursAndMinutes}
                />
              </Col>
              <Col sm={2} className="d-flex align-items-center">
                <select
                  className="form-select form-select-md cursor-pointer"
                  style={{ width: 'auto', display: 'inline' }}
                  aria-label="closes at 'am' or 'pm' selection dropdown"
                  value={closesAtAmOrPm}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setClosesAtAmOrPm(event.target.value)}
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </Col>
            </>
          ) : closesInOrAt === 'in' ? (
            <>
              <Col sm={{ offset: 1, span: 2 }}>
                <Form.Control
                  type="number"
                  min="1"
                  value={closesInMinutesDaysOrHoursAmount}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setClosesInMinutesDaysOrHoursAmount(Number(event.target.value))}
                />
              </Col>
              <Col sm={2} className="d-flex align-items-center">
                <select
                  className="form-select form-select-md"
                  style={{ width: 'auto', display: 'inline' }}
                  aria-label="closes in 'minutes', 'hours' or 'days' selection dropdown"
                  value={closesInMinutesDaysOrHours}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setClosesInMinutesDaysOrHours(event.target.value as TimeUnit)}
                >
                  <option value="minutes">minutes</option>
                  <option value="hours">hours</option>
                  <option value="days">days</option>
                </select>
              </Col>
            </>
          ) : null}
        </Row>
        {localErrors.closesAt?.message ? (
          <Row className="mb-4">
            <Col className="text-center">
              <span className="text-danger">{localErrors.closesAt?.message}</span>
            </Col>
          </Row>
        ) : null}
        <Row className="mb-4">
          <Col sm={3} className="fw-bold">
            Vote Visibility:
          </Col>
          <Controller
            name="isAnonymous"
            control={control}
            render={({ field }) => (
              <>
                <Col sm={{ offset: 1, span: 2 }}>
                  <Button variant={field.value ? 'danger' : 'success'} onClick={() => setValue('isAnonymous', false)}>
                    Public
                  </Button>
                </Col>
                <Col sm={4}>
                  <Button variant={field.value ? 'success' : 'danger'} onClick={() => setValue('isAnonymous', true)}>
                    Anonymous
                  </Button>
                </Col>
              </>
            )}
          />
        </Row>
        <Row className="mb-2">
          <Col className="d-flex justify-content-center">
            <h4>Poll Voting Options</h4>
          </Col>
        </Row>
        {fields.map((field, index) => (
          <Row key={field.id} className="mb-2">
            <Col md={{ offset: 1, span: isAnonymous ? 8 : 6 }} className="d-flex flex-column">
              <span className="fw-bold">Option #{index + 1} Text:</span>
              <Form.Control
                id={`rtn-form-poll-option-${field.id}`}
                isInvalid={!!localErrors[`pollOptions/${index}/text`]?.message}
                placeholder={`Voting option #${index + 1} text`}
                {...register(`pollOptions.${index}.text`)}
              />
              <Form.Control.Feedback type="invalid">{formatPollOptionErrorMessage(localErrors[`pollOptions/${index}/text`]?.message)}</Form.Control.Feedback>
            </Col>
            {!isAnonymous ? (
              <Col md={2} className="position-relative">
                <span className="fw-bold">Emoji:</span>
                {showEmojiPicker[field.id] ? (
                  <div style={{ position: 'absolute', zIndex: '100', bottom: '0' }}>
                    <Controller
                      name={`pollOptions.${index}.emoji`}
                      control={control}
                      render={({ field: { onChange } }) => (
                        <Picker
                          data={data}
                          theme={currentThemeType}
                          onClickOutside={() => setShowEmojiPicker({ ...showEmojiPicker, [field.id]: false })}
                          onEmojiSelect={(selectedEmoji: { native: string }) => {
                            onChange(selectedEmoji.native)
                            setShowEmojiPicker({ ...showEmojiPicker, [field.id]: false })
                          }}
                        />
                      )}
                    />
                  </div>
                ) : null}
                {!showEmojiPicker[field.id] ? (
                  <Form.Control
                    style={{ textAlign: 'center' }}
                    isInvalid={!!localErrors[`pollOptions/${index}/emoji`]?.message}
                    {...register(`pollOptions.${index}.emoji`)}
                    onBlur={() => setShowEmojiPicker({ ...showEmojiPicker, [field.id]: false })}
                    onFocus={() => setShowEmojiPicker({ ...showEmojiPicker, [field.id]: true })}
                  />
                ) : null}
                {localErrors[`pollOptions/${index}/emoji`]?.message ? <Form.Control.Feedback type="invalid">Emoji required</Form.Control.Feedback> : null}
              </Col>
            ) : null}
            <Col
              md={2}
              className={`d-flex align-items-${
                localErrors[`pollOptions/${index}/text`]?.message || localErrors[`pollOptions/${index}/emoji`]?.message ? 'center' : 'end'
              }`}
            >
              <Button
                variant="danger"
                onClick={() => {
                  remove(index)
                  clearErrors('pollOptions')
                }}
              >
                Remove
              </Button>
            </Col>
          </Row>
        ))}
        {errors.pollOptions?.message ? (
          <Row className="mb-3">
            <Col className="text-center text-danger">
              {errors.pollOptions?.message.replace(/^PollOptions/, 'Poll Voting Options').replace(/items$/, 'options')}
            </Col>
          </Row>
        ) : null}
        <Row className="mt-4">
          <Col md={{ offset: 3, span: 6 }} className="d-flex justify-content-center">
            <Button
              variant="info"
              onClick={async () => {
                if (fields.length >= 10) {
                  setError('pollOptions', { type: 'maxLength', message: 'You may only specify a maximum of 10 voting options' })
                } else {
                  append({ text: '', emoji: '', guildId })
                  const newIndex = fields.length
                  setValue(`pollOptions.${newIndex}.emoji`, emojisByKey[newIndex + 1])
                  clearErrors('pollOptions')
                }
              }}
            >
              Add Voting Option
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center align-items-center mt-3">
            <Button type="submit">Submit</Button>
            {onCancel ? (
              <Button onClick={onCancel} className="ms-3">
                Cancel
              </Button>
            ) : null}
          </Col>
        </Row>
      </Container>
    </form>
  )
}
