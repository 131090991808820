import { useEffect } from 'react'
import Toast from 'react-bootstrap/Toast'

import type { Notification } from '../../../redux/features/notifications/notificationsSlice'

import { NOTIFICATION_TIMEOUTS_BY_TYPE } from '../../../config/config'
import { capitalizeFirstLetter } from '../../../lib/util'

import './styles.css'

interface Props {
  notification: Notification
  onClose: () => void
}

const bootstrapBgByType = {
  info: 'info',
  success: 'success',
  alert: 'warning',
  error: 'danger'
}

export const NotificationToast = ({ notification, onClose }: Props) => {
  useEffect(() => {
    setTimeout(onClose, NOTIFICATION_TIMEOUTS_BY_TYPE[notification.type])
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Toast onClose={onClose} className="rtninja-toast-container" bg={bootstrapBgByType[notification.type]}>
      <Toast.Header>
        <strong className="me-auto">{capitalizeFirstLetter(notification.type)}</strong>
      </Toast.Header>
      <Toast.Body>{notification.message}</Toast.Body>
    </Toast>
  )
}
