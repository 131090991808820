import { type Action, createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { Params } from '@feathersjs/feathers'

import type { Guild } from '../../../models/Guild'

import { Api } from '../../../lib/api'
import { mergeData, removeData } from '../../../lib/util'

import { createNotification } from '../notifications/notificationsSlice'

interface DataState {
  loading: boolean
  data: Guild[]
  errorMessage?: string
}

const initialState: DataState = {
  loading: false,
  data: []
}

interface IGetParams {
  id: string
  params?: Params
}

export const getAsync = createAsyncThunk('guilds/get', async ({ id, params }: IGetParams, { dispatch }): Promise<Guild | undefined> => {
  try {
    return await Api.get('guilds', id, params)
  } catch (e: unknown) {
    dispatch(
      createNotification({
        type: 'error',
        message: `Error getting discord server info: ${(e as Error).message}`
      })
    )
  }
})

export const findAsync = createAsyncThunk('guilds/find', async (_, { dispatch }): Promise<Guild[] | undefined> => {
  try {
    const { data } = await Api.find('guilds')
    return data
  } catch (e: unknown) {
    dispatch(
      createNotification({
        type: 'error',
        message: `Error getting discord servers info: ${(e as Error).message}`
      })
    )
  }
})

export const guildsSlice = createSlice({
  name: 'guilds',
  initialState,
  reducers: {
    dataAdded: (state, action: PayloadAction<Guild | Guild[]>) => {
      state.data = mergeData(state.data, action.payload)
    },
    dataRemoved: (state, action: PayloadAction<Guild | Guild[]>) => {
      state.data = removeData(state.data, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAsync.fulfilled, (state: DataState, action: PayloadAction<Guild | undefined>) => {
        if (action.payload) {
          state.data = mergeData(state.data, action.payload)
        }
      })
      .addCase(findAsync.pending, (state: DataState) => {
        delete state.errorMessage
        state.loading = true
      })
      .addCase(findAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = mergeData(state.data, action.payload)
        }
        state.loading = false
      })
      .addCase(findAsync.rejected, (state: DataState, action: Action<string> & { error: { message?: string } }) => {
        state.errorMessage = action.error.message
        state.loading = false
      })
  }
})

export const { dataAdded, dataRemoved } = guildsSlice.actions

export default guildsSlice.reducer
