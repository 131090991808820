import type { Params } from '@feathersjs/feathers'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createNotification } from '../notifications/notificationsSlice'

import type { User, UserPatch } from '../../../models/User'

import { Api } from '../../../lib/api'

export interface AuthState {
  loading: boolean
  patching: boolean

  user?: User
  errorMessage?: string
}

const initialState: AuthState = {
  loading: false,
  patching: false
}

interface IPatchParams {
  id: string
  data: UserPatch & { provider?: string }

  params?: Params
}

export const loginAsync = createAsyncThunk('users/login', async (_, { dispatch }): Promise<User | void> => {
  try {
    const result = await Api.login()
    return result
  } catch (e: unknown) {
    dispatch(createNotification({ type: 'error', message: `Error logging in: ${(e as Error).message}` }))
    throw e
  }
})

export const logoutAsync = createAsyncThunk('users/logout', async (_, { dispatch }): Promise<void> => {
  try {
    await Api.logout()
  } catch (e: unknown) {
    dispatch(createNotification({ type: 'error', message: `Error logging out: ${(e as Error).message}` }))
    throw e
  }
})

export const patchAsync = createAsyncThunk('users/patch', async ({ id, data, params }: IPatchParams, { dispatch }): Promise<User> => {
  try {
    return await Api.patch('users', id, data, params)
  } catch (e: unknown) {
    dispatch(createNotification({ type: 'error', message: `Error updating user: ${(e as Error).message}` }))
    throw e
  }
})

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        delete state.user
        state.loading = true
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.user = action.payload!
        state.loading = false
      })
      .addCase(loginAsync.rejected, (state, action) => {
        if (action.error) {
          state.errorMessage = action.error.message
        }
        delete state.user
        state.loading = false
      })
      .addCase(logoutAsync.fulfilled, (state) => {
        delete state.user
        delete state.errorMessage
      })
      .addCase(logoutAsync.rejected, (state, action) => {
        delete state.user
        state.errorMessage = action.error.message
      })
      .addCase(patchAsync.pending, (state) => {
        state.patching = true
        delete state.errorMessage
      })
      .addCase(patchAsync.fulfilled, (state, action) => {
        if (state.user && state.user.id === action.payload.id) {
          state.user = action.payload
        }
        state.patching = false
      })
      .addCase(patchAsync.rejected, (state, action) => {
        state.errorMessage = action.error.message
        state.patching = false
      })
  }
})

export default usersSlice.reducer
