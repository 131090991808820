import { Dropdown } from 'react-bootstrap'

import type { Guild } from '../../models/Guild'

type Channel = NonNullable<Guild['channels']>[number]

interface Props {
  onChannelClick: (channel: Channel) => void

  channels?: Channel[]
}

export const ChannelPickerView = ({ channels = [], onChannelClick }: Props) => {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="success">Create Poll</Dropdown.Toggle>

      <Dropdown.Menu>
        {channels.map((channel) => (
          <Dropdown.Item key={channel.id} onClick={() => onChannelClick(channel)}>
            {channel.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
