import Container from 'react-bootstrap/Container'

import type { Guild } from '../../../../models/Guild'
import type { LeavingMessage } from '../../../../models/LeavingMessage'

import { PageHeaderView as PageHeader } from '../../../PageHeader/PageHeaderView'
import { Loading } from '../../../Loading/Loading'
import { NoBotInGuildView as NoBotInGuild } from '../../../Bots/NoBotInGuild/NoBotInGuildView'

interface Props {
  loading: boolean

  guild?: Guild
  leavingMessage?: LeavingMessage
}

export const LeavingMessageView = ({ loading, guild, leavingMessage }: Props) => {
  return loading ? (
    <Loading style={{ marginTop: '32vh' }} />
  ) : !guild?.hasBot ? (
    <NoBotInGuild guild={guild} />
  ) : (
    <Container>
      <PageHeader backButtonPath={`/dashboard/${guild?.id}`}>Leaving Message</PageHeader>
    </Container>
  )
}
