import { createAsyncThunk, createSlice, type Action, type PayloadAction } from '@reduxjs/toolkit'
import type { Params } from '@feathersjs/feathers'

import type { Bot, BotPatch } from '../../../models/Bot'

import { Api } from '../../../lib/api'
import { mergeData, removeData } from '../../../lib/util'

import { createNotification } from '../notifications/notificationsSlice'

interface DataState {
  authorizing: boolean
  loading: boolean
  data: Bot[]
  errorMessage?: string
}

const initialState: DataState = {
  authorizing: false,
  loading: false,
  data: []
}

interface IPatchParams {
  id: string
  data: BotPatch
  params?: Params
}

export const findAsync = createAsyncThunk('bots/find', async (params: Params | undefined, { dispatch }): Promise<Bot[]> => {
  try {
    const { data } = await Api.find('bots', params)
    return data
  } catch (e: unknown) {
    dispatch(createNotification({ type: 'error', message: `Error getting bots: ${(e as Error).message}` }))
    throw e
  }
})

export const patchAsync = createAsyncThunk('bots/patch', async ({ id, data, params }: IPatchParams, { dispatch }): Promise<Bot> => {
  try {
    return await Api.patch('bots', id, data, params)
  } catch (e: unknown) {
    dispatch(createNotification({ type: 'error', message: `Error updating bot: ${(e as Error).message}` }))
    throw e
  }
})

const handleFulfilled = (state: DataState, action: PayloadAction<Bot | Bot[]>) => {
  if (action.payload) {
    state.data = mergeData(state.data, action.payload)
  }
  state.loading = false
}

const handleRejected = (state: DataState, action: Action<string> & { error: { message?: string } }) => {
  state.errorMessage = action.error.message
  state.loading = false
}

export const botsSlice = createSlice({
  name: 'bots',
  initialState,
  reducers: {
    dataAdded: (state, action: PayloadAction<Bot | Bot[]>) => {
      state.data = mergeData(state.data, action.payload)
    },
    dataRemoved: (state, action: PayloadAction<Bot | Bot[]>) => {
      state.data = removeData(state.data, action.payload)
    },
    setAuthorizing: (state, action: PayloadAction<boolean>) => {
      state.authorizing = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAsync.pending, (state) => {
        delete state.errorMessage
        state.data = []
        state.loading = true
      })
      .addCase(findAsync.fulfilled, handleFulfilled)
      .addCase(findAsync.rejected, handleRejected)
      .addCase(patchAsync.pending, (state) => {
        delete state.errorMessage
        state.loading = true
      })
      .addCase(patchAsync.fulfilled, handleFulfilled)
      .addCase(patchAsync.rejected, handleRejected)
  }
})

export const { dataAdded, dataRemoved, setAuthorizing } = botsSlice.actions

export default botsSlice.reducer
