import type { Action, PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { Params } from '@feathersjs/feathers'

import type { ReactionRoleOption, ReactionRoleOptionData, ReactionRoleOptionPatch } from '../../../models/ReactionRoleOption'

import { Api } from '../../../lib/api'
import { mergeData, removeData } from '../../../lib/util'

import { createNotification } from '../notifications/notificationsSlice'

interface DataState {
  loading: boolean
  data: ReactionRoleOption[]
  errorMessage?: string
}

const initialState: DataState = {
  loading: false,
  data: []
}

type ICreateParams = {
  data: ReactionRoleOptionData
  params?: Params
}

type IPatchParams = {
  id: string
  data: ReactionRoleOptionPatch
  params?: Params
}

const handlePending = (state: DataState) => {
  delete state.errorMessage
  state.loading = true
}

const handleRejection = (state: DataState, action: Action<string> & { error: { message?: string } }) => {
  state.errorMessage = action.error.message
  state.loading = false
}

export const findAsync = createAsyncThunk('reaction-role-options/find', async (params: Params, { dispatch }): Promise<ReactionRoleOption[]> => {
  try {
    const { data } = await Api.find('reaction-role-options', params)
    return data
  } catch (e: unknown) {
    dispatch(
      createNotification({
        type: 'error',
        message: `Error getting reaction-role-options: ${(e as Error).message}`
      })
    )
    throw e
  }
})

export const createAsync = createAsyncThunk(
  'reaction-role-options/create',
  async ({ data, params }: ICreateParams, { dispatch }): Promise<ReactionRoleOption | void> => {
    try {
      return await Api.create('reaction-role-options', data, params)
    } catch (e: unknown) {
      dispatch(
        createNotification({
          type: 'error',
          message: `Error creating reaction role option: ${(e as Error).message}`
        })
      )
      throw e
    }
  }
)

export const patchAsync = createAsyncThunk(
  'reaction-role-options/update',
  async ({ id, data, params }: IPatchParams, { dispatch }): Promise<ReactionRoleOption> => {
    try {
      return await Api.patch('reaction-role-options', id, data, params)
    } catch (e: unknown) {
      dispatch(
        createNotification({
          type: 'error',
          message: `Error updating reaction role option: ${(e as Error).message}`
        })
      )
      throw e
    }
  }
)

export const removeAsync = createAsyncThunk('reaction-role-options/remove', async (id: string, { dispatch }): Promise<string> => {
  try {
    await Api.remove('reaction-role-options', id)
    dispatch(createNotification({ type: 'success', message: 'ReactionRoleOption removed successfully' }))
    return id
  } catch (e: unknown) {
    dispatch(
      createNotification({
        type: 'error',
        message: `Error deleting reaction role option: ${(e as Error).message}`
      })
    )
    throw e
  }
})

const reactionRoleOptionsSlice = createSlice({
  name: 'reaction-role-options',
  initialState,
  reducers: {
    dataAdded: (state, action: PayloadAction<ReactionRoleOption | ReactionRoleOption[]>) => {
      state.data = mergeData(state.data, action.payload)
    },
    dataRemoved: (state, action: PayloadAction<ReactionRoleOption | ReactionRoleOption[]>) => {
      state.data = removeData(state.data, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAsync.pending, handlePending)
      .addCase(findAsync.fulfilled, (state, action) => {
        state.data = mergeData(state.data, action.payload)
        state.loading = false
      })
      .addCase(findAsync.rejected, handleRejection)
      .addCase(createAsync.pending, handlePending)
      .addCase(createAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.data.push(action.payload)
        }
        state.loading = false
      })
      .addCase(createAsync.rejected, handleRejection)
      .addCase(patchAsync.pending, handlePending)
      .addCase(patchAsync.fulfilled, (state, action) => {
        state.data = mergeData(state.data, action.payload)
        state.loading = false
      })
      .addCase(patchAsync.rejected, handleRejection)
      .addCase(removeAsync.pending, handlePending)
      .addCase(removeAsync.fulfilled, (state, action) => {
        state.data = state.data.filter((reactionRole) => reactionRole.id !== action.payload)
        state.loading = false
      })
      .addCase(removeAsync.rejected, handleRejection)
  }
})

export const { dataAdded, dataRemoved } = reactionRoleOptionsSlice.actions

export default reactionRoleOptionsSlice.reducer
