import type { Action, PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { Params } from '@feathersjs/feathers'

import type { Poll, PollData, PollPatch } from '../../../models/Poll'

import { Api } from '../../../lib/api'
import { mergeData, removeData } from '../../../lib/util'

import { createNotification } from '../notifications/notificationsSlice'

interface DataState {
  loading: boolean
  loaded: boolean
  data: Poll[]
  errorMessage?: string
}

const initialState: DataState = {
  loading: false,
  loaded: false,
  data: []
}

type ICreateParams = {
  data: PollData
  params?: Params
}

type IPatchParams = {
  id: string
  data: PollPatch
  params?: Params
}

const handlePending = (state: DataState) => {
  delete state.errorMessage
  state.loading = true
}

const handleRejection = (state: DataState, action: Action<string> & { error: { message?: string } }) => {
  state.errorMessage = action.error.message
  state.loading = false
}

export const findAsync = createAsyncThunk('polls/find', async (params: Params, { dispatch }): Promise<Poll[]> => {
  try {
    const { data } = await Api.find('polls', params)
    return data
  } catch (e: unknown) {
    dispatch(createNotification({ type: 'error', message: `Error getting polls: ${(e as Error).message}` }))
    throw e
  }
})

export const createAsync = createAsyncThunk('polls/create', async ({ data, params }: ICreateParams, { dispatch }): Promise<Poll | void> => {
  try {
    return await Api.create('polls', data, params)
  } catch (e: unknown) {
    dispatch(createNotification({ type: 'error', message: `Error creating poll: ${(e as Error).message}` }))
    throw e
  }
})

export const patchAsync = createAsyncThunk('polls/update', async ({ id, data, params }: IPatchParams, { dispatch }): Promise<Poll> => {
  try {
    return await Api.patch('polls', id, data, params)
  } catch (e: unknown) {
    dispatch(createNotification({ type: 'error', message: `Error updating poll: ${(e as Error).message}` }))
    throw e
  }
})

export const removeAsync = createAsyncThunk('polls/remove', async (id: string, { dispatch }): Promise<string> => {
  try {
    await Api.remove('polls', id)
    dispatch(createNotification({ type: 'success', message: 'Poll removed successfully' }))
    return id
  } catch (e: unknown) {
    dispatch(createNotification({ type: 'error', message: `Error deleting poll: ${(e as Error).message}` }))
    throw e
  }
})

const pollsSlice = createSlice({
  name: 'polls',
  initialState,
  reducers: {
    dataAdded: (state, action: PayloadAction<Poll | Poll[]>) => {
      state.data = mergeData(state.data, action.payload)
    },
    dataRemoved: (state, action: PayloadAction<Poll | Poll[]>) => {
      state.data = removeData(state.data, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAsync.pending, (state: DataState) => {
        delete state.errorMessage
        if (!state.loaded) {
          state.loading = true
        }
      })
      .addCase(findAsync.fulfilled, (state, action) => {
        state.data = mergeData(state.data, action.payload)
        state.loading = false
        state.loaded = true
      })
      .addCase(findAsync.rejected, handleRejection)
      .addCase(createAsync.pending, handlePending)
      .addCase(createAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = mergeData(state.data, action.payload)
        }
        state.loading = false
      })
      .addCase(createAsync.rejected, handleRejection)
      .addCase(patchAsync.pending, handlePending)
      .addCase(patchAsync.fulfilled, (state, action) => {
        state.data = mergeData(state.data, action.payload)
        state.loading = false
      })
      .addCase(patchAsync.rejected, handleRejection)
      .addCase(removeAsync.pending, handlePending)
      .addCase(removeAsync.fulfilled, (state, action) => {
        state.data = state.data.filter((poll) => poll.id !== action.payload)
      })
      .addCase(removeAsync.rejected, handleRejection)
  }
})

export const { dataAdded, dataRemoved } = pollsSlice.actions

export default pollsSlice.reducer
